import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  DesktopContainer,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';

import { UserContext } from 'apps/olive/src/context/UserContext';
import { OliveFileUploader } from '@rabbit/olive/components/organisms/upload-wrapper/OliveFileUploader';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { t } from 'i18next';
import { ConfigContext } from '@rabbit/config/context';
import { useGetConsumerHoldings } from '@rabbit/bizproc/react';

/* eslint-disable-next-line */
export interface HoldingSingleAddEditDocsProps {}

export function HoldingSingleAddEditDocs(props: HoldingSingleAddEditDocsProps) {
  const location = useLocation();
  const holdingId = location.pathname.split('/')[2];
  const navigate = useNavigate();
  const { consumerPersonaId } = useContext(UserContext) || {};
  const { config } = useContext(ConfigContext);

  if (!consumerPersonaId) return <LoadingSpinner size="sm" />;

  return (
    <DesktopContainer
      title={t('Product documents')}
      goBack={() => navigate(-1)}
    >
      <OliveFileUploader
        label={t('Proof of purchase')}
        identifiers={{
          category: UploadedFileCategories.ConsumerProofPurchase,
          docType: { docid: holdingId, type: DocTypeShapeTypes.Holding },
          personaId: consumerPersonaId ?? '',
        }}
        shouldAutoUpdateDocs={true}
        accepts={['image/*', '.pdf']}
      />
      {config?.CLAIMS.CLAIMS_FLOW.SERIAL_NUMBER_PROOF && (
        <OliveFileUploader
          label={t('Photo of serial number')}
          identifiers={{
            category: UploadedFileCategories.SerialNumberProof,
            docType: { docid: holdingId, type: DocTypeShapeTypes.Holding },
            personaId: consumerPersonaId ?? '',
          }}
          shouldAutoUpdateDocs={true}
          maxFiles={1}
          accepts={['image/*']}
        />
      )}
    </DesktopContainer>
  );
}

export default HoldingSingleAddEditDocs;
