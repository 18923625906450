import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/solid';
import { ConsumerHoldingSummaryShape } from '@rabbit/bizproc/client';
import {
  calcWarrantyTimeLeft,
  useConsumerHoldingEditor,
} from '@rabbit/bizproc/react';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import { VehicleInfo, WarrantyStatus } from '@rabbit/data/types';
import {
  formatUnixTime,
  Heading,
  Modal,
  Button,
  isWarrantyExpired,
  getIncreasedLabourRate,
  getClaimLimit,
  getWarrantyTerm,
} from '@rabbit/elements/shared-components';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { useAppInfo } from '@rabbit/olive/utils/helpers';
import { getUnixTime } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { OliveFileUploader } from '../../organisms/upload-wrapper/OliveFileUploader';
import imgWarrantyBadge from '../../../assets/images/warranty-badge.png';
import { BL_Warranty } from '@rabbit/bizproc/core';

interface RecordType {
  label: string;
  value: string | Array<string> | boolean | number | undefined;
  ValueElement?: JSX.Element;
}

export default function WarrantyDetailSection({
  holding,
}: {
  holding: ConsumerHoldingSummaryShape;
}) {
  const warrantyInfo = BL_Warranty.getLatestWarranty(holding?.warranties);
  const appInfo = useAppInfo();
  const { personaId } = useMyConsumerPersona();
  const remainingTime = calcWarrantyTimeLeft(warrantyInfo?.endDate);
  const [showWarrantyModal, setShowWarrantyModal] = useState(false);
  const [proofOfPurchaseModal, setProofOfPurchaseModal] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const CFG_WARRANTY_INFO_URL = t('CFG_WARRANTY_INFO_URL');
  const { body } = useConsumerHoldingEditor(
    personaId || '',
    holding.holdingId.split('_')[0]
  );

  const modalSettings = {
    kind: 'generic' as const,
    settings: {
      title: 'Warranty information',
      handleClose: () => setShowWarrantyModal(false),
    },
  };

  const warrantyInfoMap: RecordType[] = [
    {
      label: 'ID',
      value: warrantyInfo?.warrantyLink?.slice(0, 5).toUpperCase(),
    },
    {
      label: 'Registration date',
      value:
        holding.purchase_time && holding.purchase_time > 0
          ? formatUnixTime(holding.purchase_time, 'dd/MM/yyyy')
          : '-',
    },
    {
      label: 'Start date',
      value: warrantyInfo?.startDate
        ? formatUnixTime(getUnixTime(warrantyInfo.startDate), 'dd/MM/yyyy')
        : '-',
    },
    {
      label: 'Expiry date',
      value: warrantyInfo?.endDate
        ? formatUnixTime(getUnixTime(warrantyInfo.endDate), 'dd/MM/yyyy')
        : '-',
    },
    {
      label: 'Term',
      value: getWarrantyTerm(holding),
    },
    {
      label: 'Warranty expired',
      value: warrantyInfo?.endDate
        ? isWarrantyExpired(warrantyInfo?.endDate)
        : '-',
    },
    {
      label: 'Go to',
      value: '',
      ValueElement: RenderGoToUrl(CFG_WARRANTY_INFO_URL, 'Terms & Conditions'),
    },
  ];

  return (
    <div className="flex w-full flex-col rounded-md bg-gray-50">
      {!warrantyInfo && <RenderLoadingState />}
      {warrantyInfo && warrantyInfo.status !== WarrantyStatus.VOIDED && (
        <div className="font-nunito flex flex-col p-4">
          <div className="flex justify-between">
            <div className="flex items-center gap-4">
              <div className="h-[84px] w-[84px]">
                <img
                  src={imgWarrantyBadge}
                  alt="Warranty"
                  className="h-full w-full object-contain"
                />
              </div>
              <div className="font-nunito flex flex-col">
                <div className="font-bold">
                  {warrantyInfo.templateTitle || '-'}
                </div>
                <div className="text-base text-gray-500">Warranty</div>
                <p className="text-base text-gray-900">{appInfo.name}</p>
              </div>
            </div>
            {remainingTime?.amount && remainingTime.amount > 0 && (
              <div className="flex items-start">
                <div className="font-nunito flex flex-col items-center font-medium">
                  <Heading kind={'h2'} color="text-primary-700">
                    {remainingTime.amount.toString()}
                  </Heading>
                  <div className="text-center">
                    <p className="text-primary-700 mt-[-8px] mb-[5px] text-[8px]">
                      {remainingTime.unit}
                    </p>
                    <p className="text-[10px]">
                      Warranty
                      <br />
                      Remaining
                    </p>
                  </div>
                </div>
                {appInfo?.name
                  .toLowerCase()
                  .includes(String(holding?.brand)?.toLowerCase()) &&
                CFG_WARRANTY_INFO_URL?.length ? (
                  <div
                    className="relative z-20 cursor-pointer"
                    onClick={() => setShowWarrantyModal(true)}
                  >
                    <InformationCircleIcon className="relative top-2 h-5 w-5 text-black" />
                  </div>
                ) : null}
              </div>
            )}
            {remainingTime && remainingTime.amount === -1 && (
              <div className="font-nunito flex flex-col items-center font-medium">
                <p className="text-primary-700 text-2xl font-normal">∞</p>
                <p className="w-[75px] text-center text-[10px]">
                  Lifetime Warranty
                </p>
              </div>
            )}
            {remainingTime?.amount &&
              (remainingTime.amount < -1 || remainingTime.amount === 0) && (
                <div className="font-nunito flex flex-col items-center font-medium">
                  <ExclamationTriangleIcon className="h-10 w-10 text-red-600" />
                  <p className="w-[75px] text-center text-[10px]">
                    Warranty Expired
                  </p>
                </div>
              )}
          </div>
          <div className="mt-5 grid grid-cols-2 gap-4 md:grid-cols-6 md:gap-6">
            {warrantyInfoMap.map((info) => renderLabelValue(info))}
          </div>
        </div>
      )}

      {showWarrantyModal && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={modalSettings.kind}
          settings={modalSettings.settings}
        >
          <div className="font-nunito p-5 text-sm">
            For certain {appInfo?.name} products, component parts may have
            different warranties, please{' '}
            <a href={CFG_WARRANTY_INFO_URL} target="_blank" rel="noreferrer">
              click here
            </a>{' '}
            for more information.
          </div>
        </Modal>
      )}
      {proofOfPurchaseModal && (
        <Modal
          kind="generic"
          settings={{
            title: 'Proof of purchase',
            handleClose: () => setProofOfPurchaseModal(false),
          }}
          className="m-auto w-[724px] rounded-md border bg-white"
        >
          <div className="px-5">
            <OliveFileUploader
              label="Proof of purchase"
              identifiers={{
                category: UploadedFileCategories.ConsumerProofPurchase,
                docType: {
                  docid: holding.holdingId.split('_')[0],
                  type: DocTypeShapeTypes.Case,
                },
                personaId: personaId ?? '',
              }}
              shouldAutoUpdateDocs={true}
              //alterCaseFacts={alterCaseFacts} //No need - it's working without it
              accepts={['image/*', '.pdf']}
              //onUploadCompleted={onProofOfPurchaseUpdated} //No need - it's working without it
              currentFiles={body?.private?.receipt ?? []}
            />
            <div className="mt-4 flex w-full gap-2">
              <Button
                kind="primary"
                onClick={() => setProofOfPurchaseModal(false)}
              >
                OK
              </Button>
              <Button kind="red" onClick={() => setProofOfPurchaseModal(false)}>
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

const renderLabelValue = (info: RecordType) => (
  <div className="flex flex-col gap-1" key={info.label}>
    <div className="text-sm text-gray-500">{info.label}</div>
    {!info.ValueElement && (
      <div className="text-base text-black">{info.value}</div>
    )}
    {info.ValueElement && info.ValueElement}
  </div>
);

const RenderLoadingState = () => (
  <div className="flex flex-col gap-6 p-5">
    <div className="grid grid-cols-2 gap-5 md:grid-cols-6">
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
    </div>
    <div className="grid grid-cols-2 gap-5 md:grid-cols-6">
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
      <div className="w-full">
        {' '}
        <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
      </div>
    </div>
  </div>
);

const RenderGoToUrl = (url: string, label: string) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="flex items-center gap-1 text-base font-medium text-gray-700"
    >
      {label}
      <ArrowTopRightOnSquareIcon className="h-4 w-4 text-black" />
    </a>
  );
};
