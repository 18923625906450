import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { CardGeneral } from '../../molecules/CardGeneral/CardGeneral';
import { Modal, ModalSettingsShape } from '../../molecules/Modals/Modal/Modal';
import { UploadedFileCard } from '../../molecules/UploadedFileCard/UploadedFileCard';
import {
  formatUnixTime,
  getCountryByValue,
  getCurrencyFormat,
} from '../../utils/helpers';
import { ConsumerHoldingSummaryShape } from '@rabbit/bizproc/client';
import { useState } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { AppInfoShape, TableCover } from '@rabbit/data/types';
import { BL_Warranty } from '@rabbit/bizproc/core';

interface ConsumerHoldingWithClaim extends ConsumerHoldingSummaryShape {
  claim?: {
    id: string;
    status: string;
    date: number;
  };
  purchase_date?: string;
}
export interface HoldingDetailsSectionProps {
  data?: ConsumerHoldingWithClaim | null;
  appInfo: AppInfoShape;
  image?: string;
}

type TenantType = 'carTenants' | 'tableTenants' | 'defaultTenants';

export function HoldingDetailsSection({
  data,
  appInfo,
  image,
}: HoldingDetailsSectionProps) {
  const [claimFilesModal, setClaimFilesModal] = useState(false);
  const { t } = useTranslation();
  const CFG_SHOPIFY_URL = t('CFG_SHOPIFY_URL');

  const isDemoEnv = import.meta.env.VITE_DEMO;
  const productInfo = data?.srvInfo?.productInfo as TableCover;
  const warrantyInfo = BL_Warranty.getLatestWarranty(data?.warranties);
  const isShopifyTenant =
    CFG_SHOPIFY_URL &&
    data?.shopifyLinks?.shopId &&
    data?.shopifyLinks?.orderId;

  let tenantType: TenantType = 'defaultTenants';
  if (data?.srvInfo?.type === 'vehicle') tenantType = 'carTenants';
  if (warrantyInfo?.warrantorLink === 'W:NUCOVER' || data?.brand === 'Nu-Cover')
    tenantType = 'tableTenants';

  const modalSettingsClaimFiles: ModalSettingsShape = {
    title: 'Proof of purchase',
    handleClose: () => setClaimFilesModal(false),
  };

  const renderProofOfPurchase = () => {
    const totalFiles = data?.purchase_proof?.length ?? 0;
    return (
      <div
        className={
          'flex cursor-pointer items-center ' +
          (totalFiles === 0 ? 'text-red-500' : 'text-black')
        }
        onClick={() => setClaimFilesModal(!claimFilesModal)}
      >
        {totalFiles} file(s) uploaded{' '}
        <div>
          <ArrowTopRightOnSquareIcon className="h-5 w-5 pl-1" />
        </div>
      </div>
    );
  };

  const renderOrderDetails = () => {
    if (isShopifyTenant) {
      const orderUrl = `https://shopify.com/${data?.shopifyLinks?.shopId}/account/orders/${data?.shopifyLinks?.orderId}`;

      return (
        <a
          className="flex cursor-pointer items-center text-black"
          href={orderUrl}
          target="_blank"
          rel="noreferrer"
        >
          View
          <div>
            <ArrowTopRightOnSquareIcon className="h-5 w-5 pl-1 text-black" />
          </div>
        </a>
      );
    }
    const totalFiles = data?.purchase_proof?.length ?? 0;
    return (
      <div
        className={
          'flex cursor-pointer items-center ' +
          (totalFiles === 0 ? 'text-red-500' : 'text-black')
        }
        onClick={() => setClaimFilesModal(!claimFilesModal)}
      >
        {totalFiles} file(s) uploaded{' '}
        <div>
          <ArrowTopRightOnSquareIcon className="h-5 w-5 pl-1" />
        </div>
      </div>
    );
  };

  const getStoreLabel = () => {
    if (data?.purchase_location_other === 'Shopify')
      return t('CFG_COBRAND_SHORT_NAME');
    if (data?.full_purchase_location) {
      return `${
        data?.full_purchase_location
          ? data?.full_purchase_location?.name +
            ' - ' +
            data?.full_purchase_location?.address?.town
          : '-'
      }`;
    } else return data?.purchase_location_other ?? '-';
  };

  const getStoreCountry = () => {
    if (data?.purchase_country) return data?.purchase_country;
    if (data?.full_purchase_location?.address?.country) {
      return (
        getCountryByValue(
          data?.full_purchase_location?.address?.country?.toUpperCase()
        )?.label ?? '-'
      );
    }
  };

  const renderDetailLabel = (label: string, children: any) => {
    return (
      <div>
        <div className="mb-2 text-sm font-light">{label}</div>
        <div className="text-base font-medium">{children}</div>
      </div>
    );
  };

  const RenderDefaultView = () => {
    return (
      <>
        {isDemoEnv
          ? renderDetailLabel('Serial number', data?.serial ?? 'Not added yet')
          : null}
        {renderDetailLabel(
          'Purchase date',
          data?.purchase_time
            ? formatUnixTime(data?.purchase_time, 'dd/MM/yyyy')
            : '08/03/2022'
        )}
        {renderDetailLabel(
          'Warranty expiry date',
          data?.warranty_expiry_date && data?.warranty_expiry_date > 0
            ? formatUnixTime(data?.warranty_expiry_date, 'dd/MM/yyyy')
            : '-'
        )}
        {renderDetailLabel('Store', getStoreLabel() ?? '-')}
        {renderDetailLabel(
          'Purchase country',
          getStoreCountry() ??
            (data?.purchase_country &&
            getCountryByValue(data?.purchase_country.toUpperCase())?.label
              ? getCountryByValue(data?.purchase_country.toUpperCase())?.label
              : '-')
        )}
        {renderDetailLabel(
          'Amount paid',
          data?.purchase_price && typeof data?.purchase_price !== 'string'
            ? getCurrencyFormat(
                data?.purchase_price?.amount,
                data?.purchase_price?.currency
              )
            : '-'
        )}
        {renderDetailLabel(
          'Warranty ID',
          data?.holdingId.slice(0, 5).toUpperCase()
        )}
        {data?.purchase_proof &&
          data?.purchase_proof?.length > 0 &&
          renderDetailLabel('Proof of purchase', renderProofOfPurchase())}

        {claimFilesModal && (
          <Modal
            kind="generic"
            settings={modalSettingsClaimFiles}
            className="m-auto w-[724px] rounded-md border bg-white"
          >
            <div className="p-5">
              {data?.purchase_proof &&
                data?.purchase_proof?.map((item, index) => {
                  return (
                    <div className="mb-5 mt-3" key={index}>
                      <UploadedFileCard
                        data={item}
                        status="completed"
                        progress={100}
                      />
                    </div>
                  );
                })}
            </div>
          </Modal>
        )}
        {isShopifyTenant &&
          renderDetailLabel('Order details', renderOrderDetails())}
        {String(t('CFG_COBRAND_NAME'))
          .toLowerCase()
          .includes(data?.brand?.toLowerCase() ?? '') &&
          'CFG_COBRAND_HOLDING_DETAILS_LABEL' &&
          t('CFG_COBRAND_HOLDING_DETAILS_LABEL') !==
            'CFG_COBRAND_HOLDING_DETAILS_LABEL' &&
          renderDetailLabel(
            'Go to',
            <a
              className="flex cursor-pointer items-center text-black"
              href={t('CFG_COBRAND_HOLDING_DETAILS_LINK')}
              target="_blank"
              rel="noreferrer"
            >
              {t('CFG_COBRAND_HOLDING_DETAILS_LABEL')}
              <div>
                <ArrowTopRightOnSquareIcon className="h-5 w-5 pl-1 text-black" />
              </div>
            </a>
          )}
        {String(t('CFG_COBRAND_NAME'))
          .toLowerCase()
          .includes(data?.brand?.toLowerCase() ?? '') &&
          t('CFG_COBRAND_HOLDING_DETAILS_LABEL_2') &&
          t('CFG_COBRAND_HOLDING_DETAILS_LABEL_2') !==
            'CFG_COBRAND_HOLDING_DETAILS_LABEL_2' &&
          renderDetailLabel(
            'Go to',
            <a
              className="flex cursor-pointer items-center text-black"
              href={t('CFG_COBRAND_HOLDING_DETAILS_LINK_2')}
              target="_blank"
              rel="noreferrer"
            >
              {t('CFG_COBRAND_HOLDING_DETAILS_LABEL_2')}
              <div>
                <ArrowTopRightOnSquareIcon className="h-5 w-5 pl-1 text-black" />
              </div>
            </a>
          )}
      </>
    );
  };

  const RenderNuCoverView = () => {
    const deciderPricing = warrantyInfo?.decider;

    return (
      <>
        {renderDetailLabel('Product name', productInfo.typeofStone)}
        {renderDetailLabel(
          'Area',
          productInfo.surfaceAreaValue
            ? productInfo.surfaceAreaValue + 'm²'
            : '-'
        )}
        {renderDetailLabel('Store', appInfo.name)}
        {renderDetailLabel('Product category', 'Stones')}
        {renderDetailLabel(
          'Purchase date',
          data?.purchase_time
            ? formatUnixTime(data?.purchase_time, 'dd/MM/yyyy')
            : '-'
        )}
        {productInfo.upsellSealing &&
          renderDetailLabel(
            'Sealing',
            'Yes'
            // deciderPricing
            //   ? getCurrencyFormat(
            //       deciderPricing.decided.sealingPrice as number,
            //       appInfo.currency
            //     ) + ' + GST'
            //   : '-'
          )}
        {claimFilesModal && (
          <Modal
            kind="generic"
            settings={modalSettingsClaimFiles}
            className="m-auto w-[724px] rounded-md border bg-white"
          >
            <div className="p-5">
              {data?.purchase_proof &&
                data?.purchase_proof?.map((item, index) => {
                  return (
                    <div className="mb-5 mt-3" key={index}>
                      <UploadedFileCard
                        data={item}
                        status="completed"
                        progress={100}
                      />
                    </div>
                  );
                })}
            </div>
          </Modal>
        )}
      </>
    );
  };

  return (
    <div className="flex w-full flex-col rounded-md bg-gray-50">
      {!data?.holdingId ? (
        <div className="flex flex-col gap-6 p-5">
          <div className="flex w-full grow">
            <div>
              <div className="h-[90px] w-[90px]">
                {' '}
                <Skeleton
                  count={1}
                  baseColor="#fff"
                  highlightColor="#f1f1f1"
                  className="h-[90px] w-[90px]"
                />
              </div>
            </div>
            <div className="ml-5 flex w-full flex-col items-start justify-start">
              <div className="w-full">
                <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5 md:grid-cols-6">
            <div className="w-full">
              {' '}
              <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
            <div className="w-full">
              {' '}
              <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
            <div className="w-full">
              {' '}
              <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
            <div className="w-full">
              {' '}
              <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
            <div className="w-full">
              {' '}
              <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
            <div className="w-full">
              {' '}
              <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5 md:grid-cols-6">
            <div className="w-full">
              {' '}
              <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
            <div className="w-full">
              {' '}
              <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="w-full">
            <CardGeneral
              holdingData={data}
              imageUrl={tenantType === 'tableTenants' ? image : data?.img}
              hideRemainingWarranty={true}
            />
          </div>
          <div className="font-nunito flex flex-col gap-5 px-5 pb-5">
            {tenantType === 'defaultTenants' && (
              <div className="grid grid-cols-2 gap-5 md:grid-cols-6">
                <RenderDefaultView />
              </div>
            )}
            {tenantType === 'tableTenants' && (
              <div className="grid grid-cols-2 gap-6 md:grid-cols-5">
                <RenderNuCoverView />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default HoldingDetailsSection;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

export const calcWarrantyTimeLeft = (
  expirationDateInUnixTime?: number,
  fromDate?: number
) => {
  if (!expirationDateInUnixTime) return undefined;
  if (expirationDateInUnixTime === -1)
    return { unit: 'Lifetime Warranty', amount: null };
  if (expirationDateInUnixTime === 0) return { unit: 'n/a', amount: null };

  // Checks of a correct duration
  // 1. Every overflow is rounded up i.e. 1 month & 1 day = 2months
  // 2. It's 2 months remaining until the last 31 days

  // Today
  const from = fromDate ? new Date(fromDate) : new Date();
  const to = new Date(expirationDateInUnixTime);
  const days = differenceInCalendarDays(to, from);
  if (days < -1) return { amount: days, unit: 'expired' };
  if (days === -1) return { amount: -2, unit: 'expired' };
  if (days >= 31) {
    let totalMonths = Math.floor(days / 30.4);
    const offset = Math.floor(((days / 30.4) % 1) * 30.4);
    if (offset > 0) totalMonths += 1;
    return { amount: totalMonths, unit: 'months' };
  } else {
    return { amount: Math.floor(days), unit: 'days' };
  }
};
