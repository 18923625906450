// AUTO-GENERATED FILE - DO NOT EDIT
// AUTO-GENERATED FILE - DO NOT EDIT
// AUTO-GENERATED FILE - DO NOT EDIT
// AUTO-GENERATED FILE - DO NOT EDIT
// AUTO-GENERATED FILE - DO NOT EDIT

import { SetupGreaseGateway } from '@rabbit/grease/gateway';
import { GreaseCodex } from '@rabbit/grease/elements';

const BakedCodex: GreaseCodex = [
  {
    // domain: 'shelta.iwarranty.co',
    domain: 'localhost',
    app: 'olive',
    deployment: 'us',
    manufacturer: 'manufacturer_pinnaclewarranties',
  },
  {
    domain: 'hot-olive.web.app',
    app: 'olive',
    deployment: 'us',
    manufacturer: 'manufacturer_shelta',
  },
  {
    domain: 'launchpad-olive.web.app',
    app: 'olive',
    deployment: 'aus',
    manufacturer: 'manufacturer_shelta',
  },
  {
    domain: 'eulaunchpad-olive.web.app',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_shelta',
  },
  {
    domain: 'eudemo-olive.web.app',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_demo',
  },
  {
    domain: 'eusandbox-olive.web.app',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_shelta',
  },
  {
    domain: 'charlieoven.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_charlieoven',
  },
  {
    domain: 'warrantyireland.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_warrantyireland',
  },
  {
    domain: 'pinnaclewarranties.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_pinnaclewarranties',
  },
  {
    domain: 'nucover.iwarranty.co',
    app: 'olive',
    deployment: 'aus',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'nu-cover.iwarranty.co',
    app: 'olive',
    deployment: 'aus',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'unionaudio.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_unionaudio',
  },
  {
    domain: 'fatbikes.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_fatbikes',
  },
  {
    domain: 'saez.iwarranty.co',
    app: 'olive',
    deployment: 'us',
    manufacturer: 'manufacturer_saez',
  },
  {
    domain: 'hot-charlieoven.iwarranty.co',
    app: 'olive',
    deployment: 'us',
    manufacturer: 'manufacturer_charlieoven',
  },
  {
    domain: 'hot-warrantyireland.iwarranty.co',
    app: 'olive',
    deployment: 'us',
    manufacturer: 'manufacturer_warrantyireland',
  },
  {
    domain: 'hot-pinnaclewarranties.iwarranty.co',
    app: 'olive',
    deployment: 'us',
    manufacturer: 'manufacturer_pinnaclewarranties',
  },
  {
    domain: 'hot-nucover.iwarranty.co',
    app: 'olive',
    deployment: 'us',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'hot-nu-cover.iwarranty.co',
    app: 'olive',
    deployment: 'us',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'hot-unionaudio.iwarranty.co',
    app: 'olive',
    deployment: 'us',
    manufacturer: 'manufacturer_unionaudio',
  },
  {
    domain: 'hot-fatbikes.iwarranty.co',
    app: 'olive',
    deployment: 'us',
    manufacturer: 'manufacturer_fatbikes',
  },
  {
    domain: 'hot-saez.iwarranty.co',
    app: 'olive',
    deployment: 'us',
    manufacturer: 'manufacturer_saez',
  },
  {
    domain: 'launchpad-charlieoven.iwarranty.co',
    app: 'olive',
    deployment: 'aus',
    manufacturer: 'manufacturer_charlieoven',
  },
  {
    domain: 'launchpad-warrantyireland.iwarranty.co',
    app: 'olive',
    deployment: 'aus',
    manufacturer: 'manufacturer_warrantyireland',
  },
  {
    domain: 'launchpad-pinnaclewarranties.iwarranty.co',
    app: 'olive',
    deployment: 'aus',
    manufacturer: 'manufacturer_pinnaclewarranties',
  },
  {
    domain: 'launchpad-nucover.iwarranty.co',
    app: 'olive',
    deployment: 'aus',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'launchpad-nu-cover.iwarranty.co',
    app: 'olive',
    deployment: 'aus',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'launchpad-unionaudio.iwarranty.co',
    app: 'olive',
    deployment: 'aus',
    manufacturer: 'manufacturer_unionaudio',
  },
  {
    domain: 'eulaunchpad-charlieoven.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_charlieoven',
  },
  {
    domain: 'eulaunchpad-warrantyireland.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_warrantyireland',
  },
  {
    domain: 'eulaunchpad-pinnaclewarranties.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_pinnaclewarranties',
  },
  {
    domain: 'eulaunchpad-nucover.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'eulaunchpad-nu-cover.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'eulaunchpad-unionaudio.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_unionaudio',
  },
  {
    domain: 'eulaunchpad-fatbikes.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_fatbikes',
  },
  {
    domain: 'uslaunchpad-saez.iwarranty.co',
    app: 'olive',
    deployment: 'us',
    manufacturer: 'manufacturer_saez',
  },
  {
    domain: 'eusandbox-charlieoven.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_charlieoven',
  },
  {
    domain: 'eusandbox-warrantyireland.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_warrantyireland',
  },
  {
    domain: 'eusandbox-pinnaclewarranties.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_pinnaclewarranties',
  },
  {
    domain: 'eusandbox-nucover.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'eusandbox-nu-cover.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'eusandbox-unionaudio.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_unionaudio',
  },
  {
    domain: 'eusandbox-fatbikes.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_fatbikes',
  },
  {
    domain: 'eusandbox-transporter.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_transporter',
  },
  {
    domain: 'ussandbox-saez.iwarranty.co',
    app: 'olive',
    deployment: 'eu',
    manufacturer: 'manufacturer_saez',
  },
  {
    domain: 'shelta.iwarranty.co',
    // domain: 'localhost',
    app: 'olive',
    deployment: 'aus',
    manufacturer: 'manufacturer_shelta',
  },
];

SetupGreaseGateway(BakedCodex);
